"use client"

import { useEffect } from "react"
import { useLogger } from "next-axiom"
import { v4 as uuidv4 } from "uuid"
import { useRouter } from "next/navigation"

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  const log = useLogger()
  const router = useRouter()
  // Generate a unique identifier for the error
  const uid = uuidv4()

  useEffect(() => {
    log.error("Error captured in boundary", {
      uid: uid,
      error: error.message,
      stack: error.stack,
    })
    router.push("/")
  }, [error, log, router, uid])
  return null // Render nothing while redirecting
}
